<template>
  <div class="Home1">Home22</div>
  <a-button @click="handleRun1">GO</a-button>
  <a-button @click="handleRun2">GO2</a-button>
</template>

<script setup>
import { useRouter } from "vue-router";

const router = useRouter()
const handleRun1 = ()=>{
 // console.log(text)
  router.push({path: '/home/list'})
}
const handleRun2 = ()=>{
 // console.log(text)
  router.push({path: '/home2/home22/edit'})
}
</script>

<style lang="less" scoped></style>
